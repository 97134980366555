import { twMerge } from 'tailwind-merge';

import { LockIcon } from '@bloom/ui/components/Icons/Lock';
import { NoteText } from '@bloom/ui/components/Typography/NoteText';

export const SecurePaymentBadge: React.FC<{ className: string }> = (props) => {
  const { className } = props;

  return (
    <NoteText
      as="div"
      className={twMerge('inline-flex items-center justify-center gap-2 text-center', className)}
    >
      Secure Payment <LockIcon color="currentColor" width={12} /> SSL 256-bit Encryption
    </NoteText>
  );
};
