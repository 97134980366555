import React from 'react';

import { IIconProps } from './types';

const VisaLogo: React.FC<IIconProps> = ({ className = '', color = '#888888', width = 36 }) => {
  const height = +width * (12 / 36);

  return (
    <svg width={width} height={height} className={className} viewBox="0 0 36 12">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.136 11.62H6.1L3.822 2.678c-.108-.411-.337-.775-.675-.946A9.691 9.691 0 0 0 .363.784V.44h4.892c.676 0 1.182.517 1.266 1.118l1.182 6.451 3.035-7.57h2.953L9.136 11.622Zm6.243 0h-2.868L14.873.44h2.868l-2.362 11.18Zm6.072-8.083c.085-.602.591-.946 1.182-.946a5.211 5.211 0 0 1 2.784.515L25.923.7a7.078 7.078 0 0 0-2.615-.517c-2.784 0-4.81 1.549-4.81 3.698 0 1.635 1.436 2.494 2.448 3.011 1.096.516 1.518.86 1.434 1.376 0 .773-.844 1.118-1.687 1.118-1.013 0-2.025-.258-2.952-.689l-.507 2.409c1.013.43 2.109.602 3.122.602 3.12.085 5.06-1.462 5.06-3.784 0-2.925-3.965-3.096-3.965-4.386Zm14.003 8.084L33.177.44H30.73c-.507 0-1.013.344-1.182.86l-4.217 10.32h2.953l.589-1.633h3.628l.337 1.634h2.615Zm-4.301-8.17.842 4.214h-2.361l1.519-4.214Z"
        fill={color}
      />
    </svg>
  );
};

export { VisaLogo };
