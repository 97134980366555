import { useCallback, useMemo } from 'react';

import { AsyncStatusEnum, useFetch } from '@bloom/library/components/hooks/useFetch';

export interface ICreateBuyerRequestBody {
  firstName: string;
  lastName: string;
}

export const useBuyer = () => {
  const { post, status } = useFetch();

  const createBuyer = useCallback(
    (request: ICreateBuyerRequestBody) => {
      return post('/api/create-buyer', request);
    },
    [post]
  );

  return useMemo(
    () => ({ createBuyer, isCreatingBuyer: status === AsyncStatusEnum.PENDING }),
    [createBuyer, status]
  );
};
