import React from 'react';

import { IIconProps } from './types';

const StripeLogo: React.FC<IIconProps> = ({ className = '', color = '#888888', width = 34 }) => {
  const height = +width * (14 / 34);

  return (
    <svg width={width} height={height} className={className} viewBox="0 0 34 14">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m18.251 1.975-2.268.501V.583L18.25.09v1.884Zm4.718 1.049c-.886 0-1.455.427-1.771.724l-.118-.576h-1.988v10.822l2.26-.492.008-2.626c.326.241.805.585 1.6.585 1.618 0 3.091-1.337 3.091-4.28-.009-2.69-1.5-4.157-3.082-4.157Zm-.542 6.395c-.533 0-.85-.195-1.067-.437l-.009-3.443c.235-.27.56-.455 1.076-.455.823 0 1.392.947 1.392 2.163 0 1.244-.56 2.172-1.392 2.172Zm10.747-2.144c0-2.376-1.121-4.251-3.263-4.251-2.151 0-3.453 1.875-3.453 4.232 0 2.794 1.537 4.205 3.742 4.205 1.076 0 1.889-.251 2.504-.604V9.001c-.615.316-1.32.51-2.215.51-.877 0-1.654-.315-1.753-1.41h4.42c0-.051.003-.167.007-.301.005-.182.01-.397.01-.525Zm-4.465-.882c0-1.049.623-1.485 1.193-1.485.551 0 1.139.436 1.139 1.485h-2.332ZM15.983 3.182h2.268v8.12h-2.268v-8.12Zm-2.576 0 .145.686c.533-1.002 1.59-.798 1.88-.686v2.134c-.28-.102-1.184-.232-1.718.483v5.504h-2.26V3.182h1.953ZM9.032 1.168l-2.205.482-.01 7.435c0 1.373 1.004 2.385 2.342 2.385.74 0 1.283-.14 1.582-.306V9.279c-.29.121-1.718.548-1.718-.826V5.16h1.718V3.182H9.023l.01-2.014Zm-5.341 3.87c-.48 0-.769.14-.769.501 0 .395.498.57 1.116.785 1.006.351 2.331.814 2.337 2.529 0 1.66-1.292 2.617-3.172 2.617a6.126 6.126 0 0 1-2.468-.53V8.733c.76.427 1.717.742 2.468.742.506 0 .867-.139.867-.566 0-.438-.54-.638-1.19-.88C1.886 7.662.635 7.198.635 5.65c0-1.642 1.22-2.626 3.055-2.626.75 0 1.491.12 2.241.427v2.18c-.687-.38-1.554-.593-2.241-.593Z"
        fill={color}
      />
    </svg>
  );
};

export { StripeLogo };
