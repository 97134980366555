'use client';
import { useCallback, useEffect, useRef, useState } from 'react';

import type { Card, Payments } from '@square/web-payments-sdk-types';

import { useToggle } from '@bloom/ui/components/hooks/useToggle';
import { PrimaryButton } from '@bloom/ui/components/PrimaryButton';

import { useInfoMessage } from '@bloom/library/components/FlashMessageV2/info-message-context';
import { AsyncAction } from '@bloom/library/components/hooks/useAsyncDispatch';
import { useAsyncScript } from '@bloom/library/components/hooks/useAsyncScript';
import { AsyncStatusEnum } from '@bloom/library/components/hooks/useFetch';
import Loader from '@bloom/library/components/Loader';
import { useBuyer } from '@bloom/library/components/Payment/useBuyer';
import { setCookie } from '@bloom/library/utils/browser';
import { formatMoney } from '@bloom/library/utils/string';

interface IProps {
  additionalFee: number;
  amount: number;
  buttonRadius?: number;
  buyerFirstName: string;
  buyerLastName: string;
  className?: string;
  currencyCode: string;
  customColor: string;
  disableSubmitButton?: boolean;
  documentObj?: Document;
  isContractsSigned: boolean;
  onCharge: (token: string, verificationToken?: string) => Promise<AsyncAction>;
  onChargeSuccess: () => void;
  windowObj?: Window;
}

const SquareCardForm: React.FC<IProps> = (props) => {
  const {
    additionalFee,
    amount,
    buttonRadius,
    buyerFirstName,
    buyerLastName,
    className,
    currencyCode,
    customColor,
    disableSubmitButton,
    documentObj = document,
    isContractsSigned,
    onCharge,
    onChargeSuccess,
    windowObj = window,
  } = props;

  const [isLoading, { setFalse: stopLoading, setTrue: startLoading }] = useToggle();
  const [isSquareInitialized, setSquareInitializedState] = useState(false);
  const { showErrorMessage } = useInfoMessage();

  const { createBuyer } = useBuyer();

  const { isReady } = useAsyncScript({
    globalName: 'Square',
    scope: { document: documentObj, window: windowObj },
    src: process.env.SQUARE_SCRIPT_SRC,
  });

  const cardContainerRef = useRef<HTMLDivElement>(null);
  const cardRef = useRef<Card | null>(null);
  const paymentsRef = useRef<Payments | null>(null);

  const initializeSquare = useCallback(async () => {
    if (!windowObj.Square) {
      return;
    }

    paymentsRef.current = windowObj.Square.payments(
      process.env.SQUARE_CLIENT_ID,
      process.env.SQUARE_LOCATION_ID
    );
    cardRef.current = await paymentsRef.current.card({
      style: {
        '.input-container': {
          borderColor: '#E0E0E0',
          borderRadius: '4px',
          borderWidth: '1px',
        },
        '.input-container.is-error': {
          borderColor: '#FF325A',
          borderWidth: '1px',
        },
        '.input-container.is-focus': {
          borderColor: '#111111',
          borderWidth: '1px',
        },
        input: {
          color: '#111111',
        },
      },
    });

    if (cardContainerRef.current) {
      await cardRef.current.attach(cardContainerRef.current);
    }

    setSquareInitializedState(true);
    // autofocus the card field to fill up the space between the form and submit button
    // with the info message, so it does not fill too empty
    await cardRef.current.focus('cardNumber');
  }, [windowObj.Square]);

  useEffect(() => {
    if (windowObj.Square && isReady) {
      initializeSquare();
    }
  }, [initializeSquare, isReady, windowObj.Square]);

  async function handleNonceReceive() {
    if (!paymentsRef.current || !cardRef.current) {
      return;
    }

    const tokenResult = await cardRef.current.tokenize();
    const { errors = [], token } = tokenResult;

    if (errors.length || !token) {
      showErrorMessage(
        errors ? errors[0].message : 'Something went wrong. Please try again later.'
      );

      return;
    }

    // * Implementation notes:
    // https://developer.squareup.com/docs/api/paymentform#verifybuyer
    // * Test cards:
    // https://developer.squareup.com/docs/web-payments/sca
    const verificationDetails = {
      // InvalidFunctionArgumentError: The 'verifyBuyer' function 'verificationDetails.amount' argument must be of type 'string'.
      // See: https://developer.squareup.com/docs/api/paymentform#verifybuyer
      amount: `${+amount + additionalFee}`,
      billingContact: {},
      currencyCode,
      intent: 'CHARGE' as const, // Allowed values: "CHARGE", "STORE"
    };

    try {
      const verificationResults = await paymentsRef.current.verifyBuyer(token, verificationDetails);

      if (verificationResults) {
        return onCharge(token, verificationResults.token)
          .then((res) => {
            if (res.status === AsyncStatusEnum.SUCCESS) {
              onChargeSuccess();
            }
          })
          .catch((error) => {
            let err2 = '';
            if (typeof error === 'string') {
              err2 = error;
            } else if (error.message) {
              err2 = error.message;
            } else {
              err2 = String(error);
            }
            showErrorMessage(err2);
          });
      }

      showErrorMessage('Buyer verification failed.');
    } catch (typeError) {
      showErrorMessage(
        typeof typeError === 'object' && typeError !== null && 'message' in typeError
          ? typeError.message
          : 'Buyer verification failed.'
      );

      console.log('Square verification failed: ', typeError);
    }
  }

  function getBuyerId() {
    return createBuyer({ firstName: buyerFirstName, lastName: buyerLastName }).then((response) => {
      if (response.status === AsyncStatusEnum.SUCCESS) {
        setCookie('buyerId', response.data.buyer.id);
        return response.data.buyer.id;
      }
    });
  }

  function handleSubmit() {
    if (isLoading) {
      return;
    }

    if (!isContractsSigned) {
      showErrorMessage('You need to sign the contract first.');
      return;
    }

    startLoading();

    getBuyerId()
      .then(() => {
        return handleNonceReceive();
      })
      .finally(() => {
        stopLoading();
      });
  }

  if (isReady || process.env.NODE_ENV === 'test') {
    return (
      <form
        action="path/to/payment/processing/page"
        className={className}
        id="nonce-form"
        method="post"
        noValidate
      >
        <div className="relative mt-12 h-24" ref={cardContainerRef}>
          {isSquareInitialized ? null : <Loader />}
        </div>

        <PrimaryButton
          className="w-full"
          color={customColor}
          data-testid="submit-button"
          disabled={disableSubmitButton}
          loading={isLoading}
          onClick={handleSubmit}
          style={{ borderRadius: buttonRadius ? `${buttonRadius}px` : '' }}
          variant="custom"
        >
          Pay {formatMoney(amount + additionalFee, currencyCode)}
        </PrimaryButton>
      </form>
    );
  }

  return null;
};

export { SquareCardForm };
