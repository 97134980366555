'use client';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Form } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import { useToggle } from '@bloom/ui/components/hooks/useToggle';
import { Input } from '@bloom/ui/components/Input';
import { PrimaryButton } from '@bloom/ui/components/PrimaryButton';
import { standardColors } from '@bloom/ui/constants/colors';
import { hexToRgba } from '@bloom/ui/utils/color';

import { useInfoMessage } from '@bloom/library/components/FlashMessageV2/info-message-context';
import { FormState } from '@bloom/library/components/Form/FormState';
import { AsyncAction } from '@bloom/library/components/hooks/useAsyncDispatch';
import { useAsyncScript } from '@bloom/library/components/hooks/useAsyncScript';
import { AsyncStatusEnum } from '@bloom/library/components/hooks/useFetch';
import { useBuyer } from '@bloom/library/components/Payment/useBuyer';
import { StripeVerificationModal } from '@bloom/library/components/PaymentForm/StripeVerificationModal';
import { InvoiceTransactionStatusEnum } from '@bloom/library/types/invoice';
import { setCookie } from '@bloom/library/utils/browser';
import { formatMoney } from '@bloom/library/utils/string';

interface IFormValues {
  firstName: string;
  lastName: string;
}

interface IProps {
  additionalFee: number;
  amount: number;
  buttonRadius?: number;
  buyerFirstName?: string;
  buyerLastName?: string;
  className?: string;
  currencyCode: string;
  customColor: string;
  disableSubmitButton?: boolean;
  documentObj?: Document;
  isContractsSigned: boolean;
  onCharge: (token: string) => Promise<AsyncAction>;
  onChargeSuccess: () => void;
  payoutMethodData?: { accountId: string } | null;
  textColor?: string;
  theme?: 'light' | 'dark';
  windowObj?: Window;
}

const StripeCardForm: React.FC<IProps> = (props) => {
  const {
    additionalFee,
    amount,
    buttonRadius,
    buyerFirstName,
    buyerLastName,
    className,
    currencyCode,
    customColor,
    disableSubmitButton,
    isContractsSigned,
    onCharge,
    onChargeSuccess,
    payoutMethodData,
    textColor,
    theme = 'light',
    windowObj = typeof window === 'undefined' ? {} : window,
  } = props;

  const elementStyles = useMemo(
    () => ({
      base: {
        '::placeholder': {
          // set font family to Helvetica, Arial, sans-serif to make all inputs in the form consistent
          // stripe renders their fields in iframe, so we cannot customize font-family
          color: textColor
            ? hexToRgba(textColor, 50)
            : theme === 'dark'
              ? 'rgba(255,255,255,0.5)'
              : 'rgba(0,0,0,0.5)',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontStyle: 'italic',
        },
        // set font family to Helvetica, Arial, sans-serif to make all inputs in the form consistent
        // stripe renders their fields in iframe, so we cannot customize font-family
        color: textColor
          ? hexToRgba(textColor, 100)
          : theme === 'dark'
            ? 'rgba(255,255,255,1)'
            : 'rgba(0,0,0,1)',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontSize: '14px',
        fontSmoothing: 'antialiased',
        lineHeight: '28px',
      },
      invalid: { color: standardColors.red },
    }),
    [textColor, theme]
  );

  const elementClasses = useMemo(
    () => ({
      base: 'border',
      empty: 'border border-black-15 dark:border-white-15',
      focus: '!border-black dark:!border-white',
      invalid: 'border border-danger',
    }),
    []
  );

  const { isReady } = useAsyncScript({
    globalName: 'Stripe',
    scope: { document: props.documentObj, window: props.windowObj },
    src: process.env.STRIPE_SCRIPT_SRC,
  });

  // use re-initializationState to reset the form
  const [reInitializationState, reinitializeForm] = useState('');
  const [isLoading, { setFalse: stopLoading, setTrue: startLoading }] = useToggle();

  const { createBuyer } = useBuyer();

  const formRef = useRef<HTMLFormElement | null>(null);
  const stripeRef = useRef<stripe.Stripe | null>(null);
  const elementsRef = useRef<Array<stripe.elements.Element>>([]);

  const { showErrorMessage } = useInfoMessage();

  const [verificationDetails, setVerificationDetails] = useState<Record<
    'clientSecret' | 'redirectUrl',
    string
  > | null>(null);

  const initialValues = useMemo(
    () => ({
      firstName: buyerFirstName || '',
      lastName: buyerLastName || '',
    }),
    // Do not remove the dependency
    // We need to cleanup the form after successful payment
    [buyerFirstName, buyerLastName, reInitializationState]
  );

  // Make sure that either client is logged in
  // or buyerId is present. One of those things
  // is required to make a payment.
  function getBuyerId(values: IFormValues) {
    return createBuyer({ firstName: values.firstName, lastName: values.lastName }).then(
      (action) => {
        if (action.status === 'done') {
          setCookie('buyerId', action.data.buyer.id);
          return action.data.buyer.id;
        }
      }
    );
  }

  function getStripeNonce(values: IFormValues): Promise<string> {
    function enableInputs(form: HTMLFormElement) {
      Array.prototype.forEach.call(
        form.querySelectorAll("input[type='text'], input[type='email'], input[type='tel']"),
        (input) => {
          input.removeAttribute('disabled');
        }
      );
    }

    function disableInputs(form: HTMLFormElement) {
      Array.prototype.forEach.call(
        form.querySelectorAll("input[type='text'], input[type='email'], input[type='tel']"),
        (input) => {
          input.setAttribute('disabled', 'true');
        }
      );
    }

    return getBuyerId(values).then(
      () =>
        new Promise((resolve, reject) => {
          // Disable all inputs.
          formRef.current && disableInputs(formRef.current);

          const additionalData = {
            name: `${values.firstName} ${values.lastName}`.trim(),
          };

          // Use Stripe.js to create a token. We only need to pass in one Element
          // from the Element group in order to create a token. We can also pass
          // in the additional customer data we collected in our form.
          stripeRef.current?.createToken(elementsRef.current[0], additionalData).then((result) => {
            if (result.token) {
              resolve(result.token.id);
            } else {
              // Otherwise, un-disable inputs.
              formRef.current && enableInputs(formRef.current);
              reject(result.error);
            }
          });
        })
    );
  }

  function handleChargeSuccess() {
    elementsRef.current.forEach((el) => el.clear());
    reinitializeForm(uuidv4());
    onChargeSuccess();
  }

  function handleFormikSubmit(values: IFormValues) {
    if (isLoading) {
      return;
    }

    if (!isContractsSigned) {
      showErrorMessage('You need to sign the contract first.');
      return;
    }

    startLoading();

    // * Note: Test credit cards
    // * https://stripe.com/docs/payments/3d-secure#three-ds-cards
    getStripeNonce(values)
      .then((nonce: AsyncAction | string) => {
        if (typeof nonce === 'string') {
          return onCharge(nonce);
        }
        return nonce;
      })
      .then((res) => {
        if (res.status === AsyncStatusEnum.SUCCESS) {
          if ('transaction' in res.data) {
            if (res.data.transaction.status === InvoiceTransactionStatusEnum.PENDING_VERIFICATION) {
              setVerificationDetails(res.data.transaction.verificationDetails);
              return;
            }
          }

          if ('answer' in res.data) {
            if (res.data.answer.payload.scaRequired) {
              setVerificationDetails(res.data.answer.payload.verificationDetails);
              return;
            }
          }

          handleChargeSuccess();
        }
      })
      .catch((error) => {
        let err = '';
        if (typeof error === 'string') {
          err = error;
        } else if (error.message) {
          err = error.message;
        } else {
          err = String(error);
        }
        showErrorMessage(err);
      })
      .finally(() => {
        stopLoading();
      });
  }

  useEffect(() => {
    if (isReady) {
      stripeRef.current =
        'Stripe' in windowObj
          ? windowObj.Stripe(process.env.STRIPE_PUBLISHABLE_KEY, {
              ...(payoutMethodData?.accountId && { stripeAccount: payoutMethodData.accountId }),
            })
          : null;

      if (!stripeRef.current) {
        return;
      }

      const elements = stripeRef.current.elements({
        // fonts: [{ cssSrc: `${process.env.BLOOM_DEMO_APP_URL}/client/assets/styles.css` }],
      });

      const cardNumber = elements.create('cardNumber', {
        classes: elementClasses,
        placeholder: 'Card Number',
        style: elementStyles,
      });
      cardNumber.mount('#card-number');

      const cardExpiry = elements.create('cardExpiry', {
        classes: elementClasses,
        placeholder: 'Mo / Yr',
        style: elementStyles,
      });
      cardExpiry.mount('#card-expiry');

      const cardCvc = elements.create('cardCvc', {
        classes: elementClasses,
        placeholder: 'CVC',
        style: elementStyles,
      });
      cardCvc.mount('#card-cvc');

      const cardPostalCode = elements.create('postalCode', {
        classes: elementClasses,
        placeholder: 'Postal Code',
        style: elementStyles,
      });
      cardPostalCode.mount('#card-postal-code');

      // Listen for errors from each Element, and show error messages in the UI.
      elementsRef.current = [cardNumber, cardExpiry, cardCvc, cardPostalCode];
      elementsRef.current.forEach((element) => {
        element.on('change', (event) => {
          showErrorMessage(event?.error?.message || '');
        });
      });
    }

    return () => {
      elementsRef.current.forEach((element) => {
        element.unmount();
      });
    };
  }, [
    isReady,
    showErrorMessage,
    payoutMethodData?.accountId,
    windowObj,
    elementStyles,
    elementClasses,
  ]);

  function closeStripeAuthenticationModal() {
    setVerificationDetails(null);
  }

  return (
    <>
      <FormState<IFormValues> initialValues={initialValues} onSubmit={handleFormikSubmit}>
        {({ handleChange, values }) => {
          return (
            <Form className={className} ref={formRef}>
              <div className="grid grid-cols-8 gap-3">
                <div
                  className="col-span-8 h-11 rounded-sm py-2 pr-4 pl-3 md:col-span-4"
                  data-testid="card-number"
                  id="card-number"
                />
                <div
                  className="col-span-4 h-11 rounded-sm py-2 pr-4 pl-3 md:col-span-2"
                  data-testid="card-expiry"
                  id="card-expiry"
                />
                <div
                  className="col-span-4 h-11 rounded-sm py-2 pr-4 pl-3 md:col-span-2"
                  data-testid="card-cvc"
                  id="card-cvc"
                />

                <Input
                  className="col-span-4 md:col-span-3"
                  data-testid="stripe-card-form-first-name-input"
                  name="firstName"
                  onChange={handleChange}
                  placeholder="First Name"
                  // set font family to Helvetica, Arial, sans-serif to make all inputs in the form consistent
                  // stripe renders their fields in iframe, so we cannot customize font-family
                  style={{ fontFamily: 'Helvetica, Arial, sans-serif', fontSize: '14px' }}
                  value={values.firstName}
                />

                <Input
                  className="col-span-4 md:col-span-3"
                  data-testid="stripe-card-form-last-name-input"
                  name="lastName"
                  onChange={handleChange}
                  placeholder="Last Name"
                  // set font family to Helvetica, Arial, sans-serif to make all inputs in the form consistent
                  // stripe renders their fields in iframe, so we cannot customize font-family
                  style={{ fontFamily: 'Helvetica, Arial, sans-serif', fontSize: '14px' }}
                  value={values.lastName}
                />

                <div
                  className="col-span-8 h-11 rounded-sm py-2 pr-4 pl-3 md:col-span-2"
                  data-testid="card-postal-code"
                  id="card-postal-code"
                />
              </div>

              <PrimaryButton
                className="mt-6 w-full"
                color={customColor}
                data-testid="submit-button"
                disabled={disableSubmitButton}
                loading={isLoading}
                style={{ borderRadius: buttonRadius ? `${buttonRadius}px` : '' }}
                type="submit"
                variant="custom"
              >
                Pay {formatMoney(amount + additionalFee, currencyCode)}
              </PrimaryButton>
            </Form>
          );
        }}
      </FormState>

      <StripeVerificationModal
        clientSecret={verificationDetails?.clientSecret || ''}
        onClose={closeStripeAuthenticationModal}
        onVerificationError={showErrorMessage}
        onVerificationSuccess={handleChargeSuccess}
        redirectUrl={verificationDetails?.redirectUrl}
        stripeAccountId={payoutMethodData?.accountId}
      />
    </>
  );
};

export { StripeCardForm };
