import { Checkbox } from '@bloom/ui/components/Checkbox';
import { SelectBox } from '@bloom/ui/components/SelectBox';
import { doNothing } from '@bloom/ui/utils/empty-value';

import { H2 } from '@bloom/library/components/Typography/H2';
import { formatMoney } from '@bloom/library/utils/string';

const PaymentScheduleOption: React.FC<{
  currencyCode: string;
  'data-testid': string;
  isSelected: boolean;
  label: string;
  name?: string;
  onChange: (name: string, value: string | number) => void;
  value: string | number;
}> = (props) => {
  const { currencyCode, isSelected, label, name = '', onChange, value } = props;

  function handleBoxClick() {
    onChange(name, value);
  }

  return (
    <SelectBox
      className="flex items-center gap-3 rounded-sm px-4 py-3"
      data-testid={props['data-testid']}
      name=""
      onClick={handleBoxClick}
      selected={isSelected}
      value={value}
    >
      <Checkbox checked={isSelected} data-testid="" onChange={doNothing} type="radio" />
      <div>
        <div className="text-grey">{label}</div>
        <H2 as="p" className="text-black dark:text-white">
          {formatMoney(Number(value), currencyCode)}
        </H2>
      </div>
    </SelectBox>
  );
};

export { PaymentScheduleOption };
