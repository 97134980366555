export enum InvoiceTransactionStatusEnum {
  COMPLETE = 'COMPLETE',
  DECLINED = 'DECLINED',
  PENDING_VERIFICATION = 'PENDING_VERIFICATION',
}

interface IInvoiceTransactionBase {
  // TODO: add other fields to the interface
  id: string;
}

interface IInvoiceTransactionVerificationRequired extends IInvoiceTransactionBase {
  status: InvoiceTransactionStatusEnum.PENDING_VERIFICATION;
  verificationDetails: Record<'clientSecret' | 'redirectUrl', string>;
}

interface IInvoiceTransactionProcessed extends IInvoiceTransactionBase {
  status: InvoiceTransactionStatusEnum.COMPLETE | InvoiceTransactionStatusEnum.DECLINED;
}

export type IInvoiceTransaction =
  | IInvoiceTransactionVerificationRequired
  | IInvoiceTransactionProcessed;

export interface IInvoiceTipOption {
  amount: string;
  percentage: number;
}
