'use client';
import { SquareCardForm } from '@bloom/library/components/PaymentForm/SquareCardForm';
import { StripeCardForm } from '@bloom/library/components/PaymentForm/StripeCardForm';
import { TResponse } from '@bloom/library/types/general';

export const CreditCardPaymentOption: React.FC<{
  amount: number;
  additionalFee: number;
  buttonRadius?: number;
  buyerFirstName: string;
  buyerLastName: string;
  currencyCode: string;
  customColor: string;
  disableSubmitButton?: boolean;
  documentObj?: Document;
  isContractsSigned: boolean;
  onCharge: (token: string, verificationToken?: string) => Promise<TResponse<unknown>>;
  onChargeSuccess: () => void;
  payoutMethodData?: { accountId: string } | null;
  payoutMethodProvider: string;
  theme?: 'light' | 'dark';
  windowObj?: Window;
}> = (props) => {
  const {
    additionalFee,
    amount,
    buttonRadius,
    buyerFirstName,
    buyerLastName,
    currencyCode,
    customColor,
    disableSubmitButton,
    documentObj,
    isContractsSigned,
    onCharge,
    onChargeSuccess,
    payoutMethodData,
    payoutMethodProvider,
    theme,
    windowObj,
  } = props;

  if (/^stripe/i.test(payoutMethodProvider)) {
    return (
      <StripeCardForm
        additionalFee={additionalFee}
        amount={amount}
        buttonRadius={buttonRadius}
        currencyCode={currencyCode}
        customColor={customColor}
        disableSubmitButton={disableSubmitButton}
        documentObj={documentObj}
        isContractsSigned={isContractsSigned}
        onCharge={onCharge}
        onChargeSuccess={onChargeSuccess}
        payoutMethodData={payoutMethodData}
        theme={theme}
        windowObj={windowObj}
      />
    );
  }

  if (/^square/i.test(payoutMethodProvider)) {
    return (
      <SquareCardForm
        additionalFee={additionalFee}
        amount={amount}
        buttonRadius={buttonRadius}
        buyerFirstName={buyerFirstName}
        buyerLastName={buyerLastName}
        currencyCode={currencyCode}
        customColor={customColor}
        disableSubmitButton={disableSubmitButton}
        documentObj={documentObj}
        isContractsSigned={isContractsSigned}
        onCharge={onCharge}
        onChargeSuccess={onChargeSuccess}
        windowObj={windowObj}
      />
    );
  }

  return null;
};
