'use client';
import React, { useEffect, useMemo, useState } from 'react';

import { Form } from 'formik';
import {
  PlaidLinkOnSuccess,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOptions,
  usePlaidLink,
} from 'react-plaid-link';
import { twMerge } from 'tailwind-merge';
import * as yup from 'yup';

import { TempCredentialRequest } from '@bloom/codegen/models/TempCredentialRequest';
import { TempCredentialResource } from '@bloom/codegen/models/TempCredentialResource';
import { TempCredentialResponse } from '@bloom/codegen/models/TempCredentialResponse';

import { Checkbox } from '@bloom/ui/components/Checkbox';
import { useToggle } from '@bloom/ui/components/hooks/useToggle';
import { Input } from '@bloom/ui/components/Input';
import { PrimaryButton } from '@bloom/ui/components/PrimaryButton';
import { SelectBox } from '@bloom/ui/components/SelectBox';
import { doNothing } from '@bloom/ui/utils/empty-value';

import { getErrorMessageFromResponse } from '@bloom/library/components/FlashMessageV2/actions';
import { useInfoMessage } from '@bloom/library/components/FlashMessageV2/info-message-context';
import { FormState } from '@bloom/library/components/Form/FormState';
import { AsyncAction } from '@bloom/library/components/hooks/useAsyncDispatch';
import { useAsyncScript } from '@bloom/library/components/hooks/useAsyncScript';
import { useCustomColor } from '@bloom/library/components/hooks/useCustomColor';
import { AsyncStatusEnum, useFetch } from '@bloom/library/components/hooks/useFetch';
import { useBuyer } from '@bloom/library/components/Payment/useBuyer';
import { H2 } from '@bloom/library/components/Typography/H2';
import { setCookie } from '@bloom/library/utils/browser';
import { formatAmount } from '@bloom/library/utils/string';

interface IProps {
  amount: number;
  buttonRadius?: number;
  buyerFirstName: string;
  buyerLastName: string;
  className?: string;
  currencyCode: string;
  customColor?: string;
  disableSubmitButton?: boolean;
  isContractsSigned: boolean;
  onCharge: (token: string) => Promise<AsyncAction>;
  onChargeSuccess: () => void;
}

interface IPlaidLinkButtonProps {
  amount: number;
  buttonRadius?: number;
  buyerFirstName: string;
  buyerLastName: string;
  className?: string;
  currencyCode: string;
  customColor?: string;
  disableSubmitButton?: boolean;
  onPlaidAccessTokenCreate: PlaidLinkOnSuccess;
}

type TFormValues = Record<'firstName' | 'lastName', string>;

const validationSchema = yup.object({
  firstName: yup.string().label('First Name').required(),
  lastName: yup.string().label('Last Name').required(),
});

const PlaidLinkButton: React.FC<IPlaidLinkButtonProps> = (props) => {
  const {
    amount,
    buttonRadius,
    buyerFirstName,
    buyerLastName,
    className,
    currencyCode,
    customColor: customColorProp,
    disableSubmitButton,
    onPlaidAccessTokenCreate,
  } = props;

  const { post } = useFetch();

  const [plaidLinkToken, setPlaidLinkToken] = useState('');

  const [isLoading, { setFalse: stopLoading, setTrue: startLoading }] = useToggle();

  const { showErrorMessageFromResponse } = useInfoMessage();
  const { createBuyer } = useBuyer();

  const initialValues = useMemo(
    () => ({ firstName: buyerFirstName, lastName: buyerLastName }),
    [buyerFirstName, buyerLastName]
  );

  useAsyncScript({ globalName: 'Stripe', src: process.env.STRIPE_SCRIPT_SRC });

  const customColor = useCustomColor(customColorProp);

  const config: PlaidLinkOptions = {
    onSuccess: onPlaidAccessTokenCreate,
    // token must be the same token used for the first initialization of Link
    token: plaidLinkToken,
  };

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (ready) {
      open();
    }
  }, [open, ready]);

  function createPlaidLinkToken(buyerId: string) {
    return post<{ link_token: string }, { buyerId: string }>(
      `${process.env.BLOOM_DEMO_APP_URL}/api/plaid/create_link_token`,
      { buyerId }
    );
  }

  function handleSubmit(values: TFormValues) {
    startLoading();

    createBuyer(values)
      .then((res) => {
        if (res.status === AsyncStatusEnum.SUCCESS) {
          setCookie('buyerId', res.data.buyer.id);
          return res.data.buyer.id;
        }

        if (res.status === AsyncStatusEnum.ERROR) {
          showErrorMessageFromResponse(res);
        }
      })
      .then((buyerId) => createPlaidLinkToken(buyerId))
      .then((res) => {
        let plaidLinkToken = '';
        if (res.status === AsyncStatusEnum.SUCCESS) {
          plaidLinkToken = res.data.link_token;
        }
        setPlaidLinkToken(plaidLinkToken);
      })
      .finally(() => {
        stopLoading();
      });
  }

  return (
    <FormState<TFormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ errors, handleChange, values }) => {
        return (
          <Form className="mt-6 grid grid-cols-2 gap-3">
            <Input
              className="col-span-2 sm:col-span-1"
              data-testid="stripe-ach-form-first-name-input"
              errorMessage={errors.firstName}
              name="firstName"
              onChange={handleChange}
              placeholder="First Name"
              value={values.firstName}
            />

            <Input
              className="col-span-2 sm:col-span-1"
              data-testid="stripe-ach-form-last-name-input"
              errorMessage={errors.lastName}
              name="lastName"
              onChange={handleChange}
              placeholder="Last Name"
              value={values.lastName}
            />

            <PrimaryButton
              className={twMerge('col-span-2', className)}
              color={customColor}
              data-testid="stripe-ach-form-initialize-button"
              disabled={disableSubmitButton}
              loading={isLoading}
              style={{ borderRadius: buttonRadius ? `${buttonRadius}px` : '' }}
              type="submit"
              variant="custom"
            >
              Make {formatAmount(amount, currencyCode)} Payment
            </PrimaryButton>
          </Form>
        );
      }}
    </FormState>
  );
};

const StripeAchForm: React.FC<IProps> = (props) => {
  const {
    amount,
    buttonRadius,
    buyerFirstName,
    buyerLastName,
    className,
    currencyCode,
    customColor: customColorProp,
    disableSubmitButton,
    isContractsSigned,
    onCharge,
    onChargeSuccess,
  } = props;

  const { post } = useFetch();

  const customColor = useCustomColor(customColorProp);

  const { showErrorMessage } = useInfoMessage();

  const [bankAccountId, setBankAccountId] = useState('');
  const [plaidData, setPlaidData] = useState<
    ({ token: string } & PlaidLinkOnSuccessMetadata) | null
  >(null);

  const [isLoading, { setFalse: stopLoading, setTrue: startLoading }] = useToggle();

  const createPlaidAccessToken: PlaidLinkOnSuccess = (token, metadata) => {
    setPlaidData({ token, ...metadata });
  };

  function handleBankAccountSelect(_: string, value: string) {
    setBankAccountId(value);
  }

  function getPlaidBankToken(accessToken: string, accountId: string) {
    return post<{ 'temp-credential': TempCredentialResponse }, TempCredentialRequest>(
      '/api/temp-credentials',
      {
        additional: { accessToken, accountId },
        resource: TempCredentialResource.PLAID_BANK_TOKEN,
      }
    );
  }

  function handleSubmit() {
    if (isLoading || !plaidData) {
      return;
    }

    if (!isContractsSigned) {
      showErrorMessage('You need to sign the contract first.');
      return;
    }

    if (!bankAccountId) {
      showErrorMessage('Please, select one of your bank accounts');
      return;
    }

    startLoading();

    getPlaidBankToken(plaidData.token, bankAccountId)
      .then((res) => {
        if (res.status === AsyncStatusEnum.SUCCESS) {
          return onCharge(res.data['temp-credential'].sessionToken);
        }

        if (res.status === AsyncStatusEnum.ERROR) {
          showErrorMessage(getErrorMessageFromResponse(res));
        }
        return res;
      })
      .then((res) => {
        if (res.status === AsyncStatusEnum.SUCCESS) {
          onChargeSuccess();
        }
      })
      .finally(() => {
        stopLoading();
      });
  }

  if (plaidData?.token) {
    return (
      <>
        <div className="mt-6 grid gap-6 sm:grid-cols-2">
          {plaidData.accounts.map((acc) => {
            if (['checking', 'savings'].includes(acc.subtype)) {
              return (
                <SelectBox
                  className="flex gap-3"
                  data-bank-account-id={acc.id}
                  data-testid={`${acc.subtype}-account`}
                  key={acc.subtype}
                  name="bankAccountId"
                  onClick={handleBankAccountSelect}
                  role="checkbox"
                  selected={bankAccountId === acc.id}
                  tabIndex={-1}
                  value={acc.id}
                >
                  <Checkbox
                    checked={bankAccountId === acc.id}
                    data-testid=""
                    label={
                      <div>
                        <H2 as="p" className="text-black dark:text-white">
                          {plaidData.institution?.name || ''}
                        </H2>
                        <div className="text-black-50 dark:text-white-50">Ending in {acc.mask}</div>
                      </div>
                    }
                    onChange={doNothing}
                    type="radio"
                    value={acc.id}
                  />
                </SelectBox>
              );
            }
            return null;
          })}
        </div>

        <PrimaryButton
          className="mt-6 w-full"
          color={customColor}
          data-testid="submit-button"
          loading={isLoading}
          onClick={handleSubmit}
          style={{ borderRadius: buttonRadius ? `${buttonRadius}px` : '' }}
          variant="custom"
        >
          Make {formatAmount(amount, currencyCode)} Payment
        </PrimaryButton>
      </>
    );
  }

  return (
    <PlaidLinkButton
      amount={amount}
      buttonRadius={buttonRadius}
      buyerFirstName={buyerFirstName}
      buyerLastName={buyerLastName}
      className={className}
      currencyCode={currencyCode}
      customColor={customColor}
      disableSubmitButton={disableSubmitButton}
      onPlaidAccessTokenCreate={createPlaidAccessToken}
    />
  );
};

export { StripeAchForm };
