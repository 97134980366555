import React, { useCallback, useEffect } from 'react';

import { Modal } from '@bloom/ui/components/Modal';

const StripeVerificationModal: React.FC<{
  clientSecret: string;
  onClose: () => void;
  onVerificationError: (errorMessage: string) => void;
  onVerificationSuccess: () => void;
  redirectUrl?: string;
  stripeAccountId: string;
}> = (props) => {
  const {
    clientSecret,
    onClose,
    onVerificationError,
    onVerificationSuccess,
    redirectUrl,
    stripeAccountId,
  } = props;

  const handle3DAuthenticationComplete = useCallback(() => {
    if (clientSecret) {
      const stripe = window.Stripe(process.env.STRIPE_PUBLISHABLE_KEY, {
        stripeAccount: stripeAccountId,
      });

      stripe.retrievePaymentIntent(clientSecret).then((result) => {
        if (result.error) {
          onVerificationError(result.error.message);
        } else {
          if (result.paymentIntent.status === 'succeeded') {
            onVerificationSuccess();
          } else {
            onVerificationError('Authentication failed, please enter another payment method');
          }
        }

        onClose();
      });
    }
  }, [clientSecret, onClose, onVerificationError, onVerificationSuccess, stripeAccountId]);

  const handleMessage = useCallback(
    (ev) => {
      if (ev.data === '3DS-authentication-complete') {
        handle3DAuthenticationComplete();
      }
    },
    [handle3DAuthenticationComplete]
  );
  useEffect(() => {
    window.addEventListener('message', handleMessage, false);
    return () => {
      window.removeEventListener('message', handleMessage, false);
    };
  }, [handle3DAuthenticationComplete, handleMessage]);

  return (
    <Modal onClose={onClose} open={!!clientSecret} withCloseButton={false}>
      <iframe height="400" src={redirectUrl} width="600" />
    </Modal>
  );
};

export { StripeVerificationModal };
