import React from 'react';

import { IIconProps } from './types';

const PlaidLogo: React.FC<IIconProps> = ({ className = '', color = '#888888', width = 55 }) => {
  const height = +width * (22 / 55);

  return (
    <svg width={width} height={height} className={className} viewBox="0 0 55 22">
      <path
        d="M28.478 7.798c-.45-.378-1.225-.567-2.324-.567h-2.468v7.628h1.847v-2.395h.828c1 0 1.739-.226 2.207-.667.531-.495.792-1.153.792-1.981 0-.847-.297-1.522-.882-2.018Zm-2.17 2.936h-.784V8.951h.702c.856 0 1.28.297 1.28.9.008.586-.397.883-1.199.883Zm6.205-3.512h-1.919v7.628h4.152v-1.729h-2.233v-5.9Zm6.007 0-3.026 7.628h2.071l.396-1.107h2.63l.36 1.107h2.099l-3-7.628h-1.53Zm-.027 4.98.792-2.611.784 2.611h-1.576ZM44.014 14.85h1.927V7.222h-1.927v7.628Zm9.393-6.196a3.3 3.3 0 0 0-.918-.883c-.585-.369-1.378-.55-2.378-.55h-2.54v7.63h2.964c1.071 0 1.936-.352 2.584-1.055.649-.702.973-1.63.973-2.791.009-.92-.225-1.703-.684-2.351Zm-3.08 4.467h-.82v-4.17h.838c.586 0 1.045.18 1.36.55.316.369.478.891.478 1.567-.01 1.369-.622 2.053-1.856 2.053ZM8.052.818l-6.241 1.63L.09 8.727l2.161 2.206-2.189 2.171 1.622 6.304 6.214 1.739 2.188-2.17 2.153 2.206 6.241-1.63 1.72-6.278-2.152-2.206 2.189-2.17-1.622-6.305L12.402.855l-2.188 2.17L8.052.818ZM4.224 3.655l3.288-.864 1.44 1.477-2.098 2.08-2.63-2.693Zm7.214.64 1.46-1.45 3.269.919-2.675 2.656c.009-.009-2.054-2.125-2.054-2.125ZM2.063 8.213l.91-3.306L5.603 7.6l-2.1 2.08-1.44-1.467Zm12.672-.532 2.675-2.657.855 3.324-1.459 1.45c-.009 0-2.071-2.117-2.071-2.117Zm-6.647-.054 2.099-2.08 2.062 2.116-2.099 2.08-2.062-2.116Zm-3.35 3.324 2.098-2.08L8.9 10.985 6.8 13.067 4.738 10.95Zm6.646.054 2.099-2.08 2.062 2.116-2.098 2.08c0 .009-2.063-2.116-2.063-2.116Zm-9.357 2.647 1.459-1.45 2.062 2.117-2.666 2.657c-.009 0-.855-3.324-.855-3.324Zm6.007.685 2.098-2.08 2.063 2.116-2.099 2.08-2.062-2.116Zm6.647.054 2.098-2.08 1.441 1.476-.9 3.306c-.01 0-2.64-2.702-2.64-2.702ZM4.107 18.237l2.666-2.657 2.063 2.116-1.46 1.45-3.269-.91Zm7.223-.514 2.099-2.08 2.63 2.693-3.288.864-1.44-1.477Z"
        fill={color}
      />
    </svg>
  );
};

export { PlaidLogo };
